#colorWheel_2 {
    margin: 0 auto;
    height: 100px;
    width: 100px;
    position: relative;
    -webkit-transform-origin: 50px 150px;
    -moz-transform-origin: 50px 150px;
    -ms-transform-origin: 50px 150px;
    -o-transform-origin: 50px 150px;
    transform-origin: 50px 150px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

#colorWheel_2 span {
    position: absolute;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    border-style: solid;
    border-width: 150px 24px;
    box-sizing: border-box;
}

#colorWheel_2 span.color01_2 {
    -webkit-transform: rotate(-36deg);
    -moz-transform: rotate(-36deg);
    -ms-transform: rotate(-36deg);
    -o-transform: rotate(-36deg);
    transform: rotate(-36deg);
}

#colorWheel_2 span.color02_2 {
    -webkit-transform: rotate(-18deg);
    -moz-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    -o-transform: rotate(-18deg);
    transform: rotate(-18deg);
}

#colorWheel_2 span.color03_2 {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}

#colorWheel_2 span.color04_2 {
    -webkit-transform: rotate(18deg);
    -moz-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    -o-transform: rotate(18deg);
    transform: rotate(18deg);
}

#colorWheel_2 span.color05_2 {
    -webkit-transform: rotate(36deg);
    -moz-transform: rotate(36deg);
    -ms-transform: rotate(36deg);
    -o-transform: rotate(36deg);
    transform: rotate(36deg);
}

.pointer_2 {
    border-left: 3px solid black;
    position: absolute;
    height: 150px;
    top: -1%;
    left: 23%;
    z-index: 999;
    transform-origin: bottom;
}

.pointerEnd_2 {
    border: 3px solid black;
    background-color: black;
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    top: -6%;
    margin-left: -7px;
    z-index: 999;
    transform-origin: bottom;
}