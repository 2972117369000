// * {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

body {
  margin: 0px;
  padding: 0px;
  touch-action: manipulation;
}

// material Ui Overrides
.app-window {
  .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .maxw-100 {
    max-width: 100% !important;
  }
}

.text-heaven {
  text-shadow: -1px -1px 8px #0005, 1px -1px 8px #0005, -1px 1px 8px #0005, 1px 1px 8px #0005;
}

.text-heaven-dark {
  text-shadow: -1px -1px 8px #000a, 1px -1px 8px #000a, -1px 1px 8px #000a, 1px 1px 8px #000a;
}

/* CLASS BASED STYLING */
.min-w-100vw {
  min-width: 100vw;
}
.min-h-100vh {
  min-height: 100vh;
}
.image-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 110%;
  height: 110vh;
  padding: 0px;
  margin: 0px;
  border: 0px;
}
.no-text-decor {
  text-decoration: none !important;
}
.no-list-style-type {
  list-style-type: none !important;
}

.center-cropped-img {
  object-fit: none; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  height: 50px;
  width: 50px;
}

.circle-border-radius {
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.datepicker-wrapper-full-width > div {
  width: 100%;
}

.hover-effect:hover {
  color: #323436 !important;
  background: #e8e9ea !important;
}

.hover-permanent {
  color: #121416 !important;
  background: #e8e9ea !important;
}

.sticky-bottom {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
}

.input-appear-negative input[type="number"]::before {
  content: "-";
}

.hide-scrollbar-only::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scrollbar-only {
  -ms-overflow-style: none;
}

.display-inline {
  display: inline;
}

.no-outline {
  outline: none;
}

.app-bg-main {
  //background-color: url('');
  background-size: cover;
  background-repeat: no-repeat;
  // background: black;
}

// Views

.dash-grid {
  max-width: 700px;
  margin: 0 auto;
  margin-top: 40px;
  background: #006fff;
}

.view-reports {
  .main-content {
    background-color: #171717e0;
    max-width: 1000px;
    margin: 2% auto;
    border: 1px solid #4c4c4c;
  }
}

.bg {
  backdrop-filter: blur(6px);
}

.app-window {
  background: #1b1b1b;
}

.new-fitting {
  padding-top: 40px;

  .MuiPaper-root {
    color: #fff;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: transparent;
  }
}
.MuiTextField-root {
  padding-bottom: 1.2em !important;
}

.MuiInputBase-input {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-bottom: 20px;
}

.MuiInputBase-input:focus {
  font-size: 1.2em;
}

.metrics {
  .MuiOutlinedInput-input {
    font-size: 2em;
  }
}

.report-content {
  //background: pink;
  padding: 1em;
}

.footer-poweredby {
  padding: 1em;
}

// --------------------------------

body {
  font-family: "OPTIHandelGothic-Light", sans-serif;
  color: #071b29;
}
@font-face {
  src: url("./assets/fonts/OPTIHandelGothic-Light.otf") format("opentype");
  font-family: "OPTIHandelGothic-Light";
}

/*common css */
.border-gray {
  border: 1px solid #0000001f;
}
.text-red {
  color: #f00;
}
.btn-darkblue {
  background: #063160;
  border: 1px solid #063160;
}
.btn-darkblue:hover {
  background: #021e3b;
}
.btn-outline-darkblue {
  border: 1px solid #063160;
  background-color: transparent;
  color: #063160 !important;
}
/* end common css*/
/* Login/Reg/tnc/fergot pwd */
.loginForm::placeholder,
.reg-form::placeholder {
  font-size: 18px;
  color: #071b29 !important;
}
.form-signin {
  width: 100%;
  max-width: 385px;
  margin: auto;
}
.loginForm .wrapper,
.reg-form .wrapper,
.terms-and-conditions .wrapper {
  border: 10px solid #4b8bce !important;
  background-color: #e6e6ee;
}
.loginForm,
.dashboardBlock,
.reg-form,
.terms-and-conditions {
  background: url("./assets/images/batrx_splash.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.loginForm a,
.dashboardBlock a,
.reg-form a {
  text-decoration: none;
  color: #071b29;
}
.loginForm .input-group-text,
.dashboardBlock .input-group-text,
.reg-form .input-group-text {
  border-radius: 0.25rem 0 0 0.25rem;
  background: 0 0;
}
.loginForm .input-group-text,
.dashboardBlock .input-group-text,
.reg-form .input-group-text {
  border-radius: 0.25rem 0 0 0.25rem;
  background: 0 0;
  border-right: none;
}
.loginForm .wrapper,
.dashboardBlock .wrapper,
.reg-form .wrapper,
.terms-and-conditions .wrapper {
  padding: 2rem 55px;
  border-radius: 10px;
}
.loginForm .form-control,
.dashboardBlock .form-control,
.reg-form .form-control {
  border: 1px solid #3c4a58;
  border-left: 0 !important;
}
.loginForm .input-group-text,
.dashboardBlock .input-group-text,
.reg-form .input-group-text {
  border: 1px solid #3c4a58;
  box-shadow: 0px 3px 6px #00000029;
}
.loginForm .fa-user-circle {
  color: #007dbc;
}
.loginForm .btn-primary,
.reg-form .btn-primary {
  background: #007dbc;
  border: 1px solid #007dbc;
}
.Forgetpassword h3 {
  font-size: 30px;
  font-weight: 600;
}
.ForgetpasswordError {
  width: 100%;
  max-width: 585px;
  margin: auto;
}
.errorMsgImg {
  position: absolute;
  left: -16px;
}
.Forgetpassword .leftArrow {
  position: absolute;
  right: 20px;
  top: 10px;
}
.resendEmail .btn {
  text-align: center;
  border: 1px solid #0f191c;
  background-color: #fff;
}
.resendEmail .btn:hover {
  background: #bac0c8 0% 0% no-repeat padding-box;
  border: 1px solid #0f191c;
  color: #0f191c;
}
.terms-and-conditions .wrapper {
  width: 90%;
  margin: auto;
  text-align: left;
}
.terms-and-conditions .btn-outline-dark {
  color: #071b29;
  border: 1px solid #071b29;
  letter-spacing: -0.32px;
}
.terms-and-conditions .btn-outline-dark:hover {
  color: #fff;
}
.scrollable-tnc {
  height: 500px;
  overflow-y: scroll;
  border: 1px solid #bcbdcb;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.ForgetpwdValidations .errorStyle {
  border-left: 4px solid #f00 !important;
}
.ForgetpwdValidations .activeStyle {
  border-left: 4px solid #00b3fe !important;
}
.ForgetpwdValidations > .custom-select:not(:first-child),
.ForgetpwdValidations > .form-control:not(:first-child) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
/* User Profile */
.eye-icon {
  top: 10px;
  right: 10px;
  position: absolute;
  color: #78879a;
  cursor: pointer;
  z-index: 9;
}
.captcha-img {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 28px;
  height: 28px;
}
.robotcheckbox {
  background-color: #fff;
}

/* dashboard Block*/
.dashboardBlock .logo {
  max-width: 585px;
  text-align: center;
  margin: 0 auto;
}
.btn-dashboard {
  opacity: 0.9;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
  border: 5px solid #0071ff;
  font-size: 28px;
  background-color: #042235;
  color: #07d4e7;
  padding: 20px 15px !important;
}
.btn-dashboard:hover {
  color: #fff;
}
.btn-dashboard img {
  width: 82px;
}
.dashboardBlock .fa-angle-double-right {
  color: #919da5;
  position: relative;
  top: 8px;
  float: right;
  transition: all 0.5s;
  font-size: 28px;
}
.userprofileImg {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border: 1px solid #07d4e7;
}
.userProfile a {
  font-size: 18px;
}
.userProfile a:hover {
  text-decoration: none;
}
/* End dashboard Block*/
/* playerLibrary */
.playerLibrary {
  background-color: #e6e6ee;
  height: calc(100vh - 78px);
}
.playerLibrary table td {
  font-size: 18px;
  color: #071b29;
  padding: 8px 0.75rem;
}
.customBorder {
  border-top: 6px solid #063160 !important;
  border-right: 6px solid #063160 !important;
  border-left: 6px solid #063160 !important;
}
.playerLibrary table {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.playerLibrary table th {
  color: #071b29;
  background: rgb(255, 255, 255);
  vertical-align: middle;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(204, 204, 204, 1) 100%,
    rgba(215, 214, 214, 0.9752275910364145) 100%
  );
}
span.userImg img {
  margin-right: 20px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
a.userDetails {
  color: #071b29;
}
a.userDetails:hover {
  text-decoration: none;
}
span.user img {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border: 1px solid #ccc;
}
.playerLibrary table i.fa.fa-pencil,
.playerLibrary table i.fa.fa-star-o,
.playerLibrary table i.fa.fa-star,
.playerLibrary table .fa-trash {
  border: 1px solid #5f8aa4;
  padding: 5px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  color: #071b29;
  font-size: 16px;
}
.playerLibraryHeading h3 {
  font-size: 30px;
  margin: 0px;
}
.playerTable .fa-angle-down,
.playerTable .fa-angle-up {
  font-size: 24px;
  color: #071b29;
}
.table-wrapper {
  border: 9px solid #49688b;
  border-radius: 10px;
}
.playerLibrary .table-bordered td,
.playerLibrary .table-bordered th {
  border: 1px solid #5f8aa4;
}
.playerLibrary .table-bordered td,
.playerLibrary .table-bordered th {
  border-right: 6px solid #49688b;
}
.searchBar input[type="text"] {
  border: 1px solid #487fc0;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  font-size: 16px;
  color: #071b29;
  padding-left: 50px;
}
a.btn.btn-secondary.linearGrad {
  background-color: #a8abb8 !important;
  color: #071b29;
}
.bg-gray {
  background-color: #b7b9c3;
}
.searchBar {
  /* margin-left:40px;position:relative;width:59%;*/
  position: relative;
}
.searcIcon {
  position: absolute;
  left: 20px;
  top: 10px;
  font-size: 20px;
}
.mostRecent,
.addPlayer {
  background: transparent linear-gradient(180deg, #a8abb8 0%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat
    padding-box;
  background: transparent linear-gradient(180deg, #a8abb8 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001f;
  border: 0.5px solid #0000000a;
  opacity: 1;
  color: #071b29;
  padding: 8px 20px;
  cursor: default;
  border: 1px solid #062b56;
}
.addPlayermodal {
  background: #e6e6ee; /* padding:0px 50px;*/
  border: 14px solid #4b8bce;
  border-radius: 20px;
}
.addPlayermodal .close {
  position: absolute;
  right: -28px;
  top: -18px;
  padding: 0px;
  margin: 0px;
  background: rgb(6, 49, 96);
  border-radius: 20px;
  box-shadow: none;
  text-shadow: none;
  color: rgb(255, 255, 255);
  opacity: unset;
  line-height: 1.2;
  width: 32px;
  height: 32px;
}
.addPlayermodal .form-control {
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #3c4a58;
  border-radius: 6px;
}
.starred {
  padding: 8px 10px;
}
.reviewInfo .border-bottom {
  border-bottom: 1px solid #0000001f !important;
}
.reviewInfo label {
  color: #676e75;
  font-size: 14px;
}
.all {
  padding: 8px 10px;
}
.group {
  /* border:1px solid #487FC0;*/
  background-color: #b7b9c3;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.export {
  background: transparent linear-gradient(180deg, #a8abb8 0%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat
    padding-box;
  background: transparent linear-gradient(180deg, #a8abb8 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001f;
  border: 1px solid #062d58;
  border-radius: 4px;
  opacity: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 0.5rem 0.75rem;
}
button.btn.export.solid:hover {
  background-color: darkgreen !important;
  color: white;
}
.solid {
  outline-color: #6eccd9;
  outline-style: solid;
  outline-width: 0.5px;
}
.addPlayer {
  cursor: pointer;
  padding: 9px 10px;
}
// .scrollable {
//   height: 520px;
//   overflow-y: auto;
//   overflow-x: auto;
// }
/* scrollable width */
.scrollable::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.scrollable::-webkit-scrollbar-track {
  /*box-shadow:inset 0 0 5px #f00;*/
  background-color: #063160;
}
/* Handle */
.scrollable::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
/*Header */
header {
  padding: 15px;
  height: 78px;
  background-color: #063160;
  z-index: 1;
}
header .fa-bars {
  color: #07d4e7 !important;
  box-shadow: 0px 3px 56px #00000039;
  font-size: 28px;
}
.leftSidebar {
  width: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: #063160;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-top: 20px;
  z-index: 1;
}
.closebtn {
  border-radius: 50%;
  color: #063160;
  font-size: 28px;
  font-weight: bold; /* float:right;*/
  background: #fff;
  position: absolute;
  right: 15px; /* padding:0px 11px;*/
  height: 37px;
  width: 37px;
  text-align: center;
  line-height: 1.2;
}
.closebtn:hover {
  text-decoration: none;
  color: #063160;
}
.playerLabMenu {
  padding: 4.5rem 1rem !important;
}
.playerLabMenu img {
  margin-right: 10px;
  width: 38px;
  height: auto;
}
ul.playerLabMenu li {
  margin-bottom: 10px;
}
ul.playerLabMenu li a.nav-link {
  color: #fff;
  font-size: 18px;
  padding: 1.3rem 1rem;
}
ul.playerLabMenu li.nav-item.active {
  background: #07d4e7;
  border-radius: 15px;
}
ul.playerLabMenu li.nav-item.active a {
  color: #ffffff;
}
ul.playerLabMenu li.nav-item a:hover {
  background: #07d4e7;
  border-radius: 15px;
  color: #fff;
}
ul.playerLabMenu li a .fa.fa-angle-double-right.float-right {
  position: relative;
  top: 9px;
}
.scrollableTab {
  height: 25rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
.dashboard-user-profile {
  right: 15px;
  top: 15px;
}
.dashboard-user-profile a:hover {
  text-decoration: none;
}
.progressBar {
  width: 38%;
  border-top: 14px solid #063160;
  border-radius: 0 0 0 7px;
}
#biometrics .progressBar {
  width: 68%;
  border-radius: 0 0 0 7px;
  -webkit-border-radius: 0 0 0 7px;
  -moz-border-radius: 0 0 0 7px;
  -ms-border-radius: 0 0 0 7px;
  -o-border-radius: 0 0 0 7px;
}
#review .progressBar {
  width: 100%;
  border-radius: 0 0 7px 7px;
}
.addBatModal .tabWrapper {
  margin-top: 27px;
}
.pageNumber {
  display: flex;
  align-items: center;
  float: right;
}
.pageNumber a,
.pageNumber span {
  font-size: 24px;
  color: #063160;
}
.batSearchTable {
  background: #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.table-border {
  border: 1px solid #5f8aa4 !important;
}
.batSearchTable tr td,
.batSearchTable tr th {
  border: 1px solid #5f8aa4 !important;
  padding: 0.45rem;
}
.batSearchTable tr th {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(204, 204, 204, 1) 100%,
    rgba(215, 214, 214, 0.9752275910364145) 100%
  );
}
.batSearchTable {
  background: rgb(255, 255, 255);
  vertical-align: middle;
}
.scroolbar {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 340px;
}
.batSearchBlock {
  margin-top: 27px;
}
/* .scrollableTab::-webkit-scrollbar{width:15px;}
*/
/* Track */
.scrollableTab::-webkit-scrollbar {
  width: 10px;
}
.scrollableTab::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
/* Handle */
.scrollableTab::-webkit-scrollbar-thumb {
  background: #bbb;
}
.tabWrapper {
  margin: 0 50px;
}
/* Switch Button */
.switchBtn {
  display: flex;
  align-items: center;
}
.switchBtn .switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 15px;
}
.switchBtn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switchBtn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 12px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switchBtn .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -9px;
  bottom: -2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switchBtn input:checked + .slider {
  background-color: #4b8bce;
}
.switchBtn input:focus + .slider {
  box-shadow: 0px 2px 7px #0000005d;
}
.switchBtn input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  box-shadow: 0px 2px 7px #0000005d;
}
/* Rounded sliders */
.switchBtn .slider.round {
  border-radius: 34px;
}
.switchBtn .slider.round:before {
  border-radius: 50%;
}
/* End Switch Button */
@media only screen and (max-width: 992px) {
  .mobileMargin {
    margin-bottom: 20px;
  }
}

/* Get Fitted Page*/
.w-10 {
  width: 10%;
}

.stickyPagination {
  position: fixed;
  bottom: 0;
  background: transparent linear-gradient(180deg, #475663 0%, #6e7388 71%, #82879a 100%) 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 7px 20px;
}

.stickyPagination .btn {
  font-size: 25px;
  padding: 5px 20px;
  color: #071b29;
}

.backBtn {
  background: transparent linear-gradient(180deg, #808597 0%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat
    padding-box;
  background: transparent linear-gradient(180deg, #808597 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #86b3ce;
  border: 0.5px solid #0000000a;
  border-radius: 8px;
}

.nxtBtn {
  background: transparent linear-gradient(180deg, #d0d7de 0%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat
    padding-box;
  background: transparent linear-gradient(180deg, #d0d7de 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001f;
  border: 0.5px solid #86b3ce;
  border-radius: 8px;
}

.speed {
  font-size: 44px;
  color: #063160;
}

.speed-small {
  font-size: 24px;
  color: #063160;
}

.lastHit .border {
  background: #d9dfea;
  border-radius: 3px;
  width: 80% !important;
  margin: auto;
}

.lastHit .card-header {
  font-size: 22px;
}

.lastHit .card-footer {
  font-size: 22px;
  color: #071b29;
  padding: 15px 0 0 0;
}

.addHit {
  background-color: #063160;
  color: #fff;
}

.addHit:hover {
  color: #fff;
}

.addHitDetails {
  display: flex;
  align-items: center;
  line-height: 1;
}

.addHitTable thead {
  border-top: 6px solid #063160 !important;
}

.addHitDetails .ahdHeading {
  font-size: 32px;
}

.addHitDetails .ahdHeading small {
  font-size: 20px;
  color: #071b29;
}

.addHitDetails .ahdHeading h2 {
  font-size: 32px;
}

.border-right.pr-3.ahdHeading {
  border-right: 2px solid #ccc !important;
  border-radius: 2px;
}

.reviewBtn .fa-bar-chart {
  font-size: 18px;
}

.speed .unit {
  font-size: 34px;
}

.speed-small .unit {
    font-size: 14px;
  }

.performanceBox,
.bestBatBox {
  border: 1px solid #adadad;
  border-radius: 8px;
  box-shadow: 0px 9px 10px #00000080;
}

.performanceBox span,
.bestBatBox span {
  font-size: 24px;
  color: #063160;
}

.performanceBox .card-header h3,
.bestBatBox .card-header h3 {
  font-size: 26px;
}

.bestBatBox .card-footer ul li {
  color: #43425d;
}

.bestBatBox .card-footer ul li::marker {
  color: #e0dff5;
  font-size: 32px;
}

.filterBtns .btn-outline-secondary {
  border: 1px solid #071b29;
  color: #071b29;
}

.filterBtns .btn-outline-secondary:hover {
  color: #338dde;
  border: 1px solid #338dde;
  background-color: transparent;
}

@media (max-device-width: 600px) {
  .mobile-text {
    text-align: center !important;
  }
}

/* Movement Sceen */
.movScreenScroll {
  height: 350px;
  overflow: auto;
}

.movScreenScroll .card .card-footer {
  font-size: 14px;
}

.movementScreening h2 {
  font-size: 34px;
  color: #071b29;
}

.movementScreening p {
  font-size: 21px;
}

.movScreenScroll .card-footer {
  background-color: #fff;
}

.successBlock {
  box-shadow: 0px 3px 8px #198754 !important;
  border: 3px solid #17eb89 !important;
}

.errorBlock {
  box-shadow: 0px 3px 8px #f00 !important;
  border: 3px solid #f00 !important;
}

/* Changes as per New UI */

.loginForm .fa-user-circle {
  color: #36a7e9;
}
.loginForm .wrapper,
.reg-form .wrapper,
.terms-and-conditions .wrapper {
  border: 10px solid #36a7e9 !important;
  background-color: #e6e6ee;
}
.loginForm .btn-primary,
.reg-form .btn-primary {
  background: #cc6727;
  border: 1px solid #cc6727;
  border-radius: 6px;
}
.loginForm a,
.dashboardBlock a,
.reg-form a {
  text-decoration: none;
  color: #646263;
}
.btn-dashboard {
  border: 5px solid #36a7e9;
  color: #fff;
}
.btn-dashboard img {
  width: 77px;
}
header {
  background-color: #36a7e9;
}
header .fa-bars {
  color: #fff !important;
}
.searchBar input[type="search"] {
  border: 1px solid #5fa841;
}
.stickyPagination .btn {
  color: #fff;
  background: #cc6727 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001f;
  border: 0.5px solid #f1f1f1a6 !important;
}
.table-wrapper {
  border: 9px solid #36a7e9;
}
.export {
  background: #5fa841 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001f;
  border: 0.5px solid #0000000a;
  border-radius: 8px;
  color: #fff;
  padding: 10px 15px;
}
.solid {
  outline: none;
}
.stickyPagination {
  background: transparent linear-gradient(180deg, #646263 0%, #8b8e98 71%, #9a9eac 100%) 0% 0% no-repeat padding-box;
}
.addPlayermodal {
  background: #e6e6ee;
  /* padding: 0px 50px; */
  border: 8px solid #36a7e9;
  border-radius: 20px;
}
.addPlayermodal .close {
  background: #cc6727;
}

.progressBar {
  border-top: 14px solid #5fa841;
}
.btn-darkblue {
  background: #cc6727;
  border: 1px solid #cc6727;
  font-size: 20px;
  border-radius: 6px;
}
.btn-darkblue:hover {
  background: #99440f;
}
.btn-outline-darkblue {
  border: 1px solid #cc6727;
  background-color: transparent;
  color: #cc6727 !important;
  font-size: 20px;
  border-radius: 6px;
}
.resendEmail .btn {
  text-align: center;
  border: 1px solid #cc6727;
  background-color: #fff;
  color: #cc6727;
  font-size: 20px;
}
.terms-and-conditions .btn-outline-dark {
  color: #cc6727;
  border: 1px solid #cc6727;
  letter-spacing: -0.32px;
}
.terms-and-conditions .btn-outline-dark:hover {
  border: 1px solid #cc6727;
  background-color: transparent;
  color: #cc6727;
}
.terms-and-conditions .btn-primary {
  color: #fff;
  background-color: #cc6727;
  border-color: #cc6727;
}
.terms-and-conditions .btn-primary:hover {
  background: #99440f;
}
.speed {
  color: #cc6727;
}

.getFitted .export {
  background-color: #cc6727 !important;
}
.addHit {
  background-color: #cc6727;
}
.filterBtns .btn-outline-secondary:hover {
  border: 1px solid #cc6727;
  color: #cc6727;
}
.mostRecent,
.addPlayer {
  background: #cc6727 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001f;
  border: 0.5px solid #0000000a;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
}

.group {
  background: #acacac 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
/* ----------------- */

.searchBar input[type="text"] {
  border: 1px solid #487fc0;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  font-size: 16px;
  color: #071b29;
  padding-left: 50px;
}
.searcIcon {
  position: absolute;
  left: 20px;
  top: 10px;
  font-size: 20px;
}
.bioInputRadio input[type="radio"] {
  transform: scale(1.5);
}
.info-circle-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}

.custom-brand-checkbox {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

// 21st Dec
.performanceBoxHeader {
  padding: 0.75rem 1.25rem;
}

.performanceBox .performanceBoxHeader button.btn-outline-secondary {
  color: #071b29 !important;
  border-color: #071b29;
}

.card.performanceBox {
  background: transparent linear-gradient(180deg, #e8f0f3 0%, #ffffff 31%, #ffffff 70%, #808d9400 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 9px 10px #00000080;
  border: 1px solid #adadad;
  border-radius: 8px;
}

.printingBlock {
  background: transparent linear-gradient(180deg, #e8f0f3 0%, #ffffff 31%, #ffffff 70%, #808d9400 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 9px 10px #00000080;
  border: 1px solid #adadad;
  border-radius: 8px;
  padding: 1rem 1.25rem;
}

.printingBlock:last-child {
  margin-bottom: 0px !important;
}

.performanceBox .performanceBoxHeader button.btn-outline-secondary:hover {
  border: 1px solid #cc6727;
  color: #cc6727 !important;
  background: transparent;
}

/* 24th Dec */
.btn-maroon {
  background-color: #cc6727;
  color: #fff;
  border-color: #cc6727;
}

.btn-maroon:hover {
  background-color: #9c4610;
  border-color: #9c4610;
}

.btn-green {
  background-color: #5fa841;
  color: #fff;
  border-color: #5fa841;
}

.btn-green:hover {
  background-color: #3b881d;
  border-color: #5fa841;
}

.lastHitControls .form-control {
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #3c4a58;
  border-radius: 6px;
}

.hitCount {
  font-weight: bold;
  font-size: 1.2rem;
  color: #cc6727;
}

.react-bootstrap-table-pagination {
  margin: 0;
}

.react-bootstrap-table-page-btns-ul .active .page-link {
  background-color: #36a7e9 !important;
  color: white !important;
}

.react-bootstrap-table-page-btns-ul .page-link {
  color: #36a7e9 !important;
}

.centered {
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.batsTable {
  height: 350px;
  overflow-y: scroll;
}
