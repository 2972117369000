#colorWheel {
    margin: 0 auto;
    height: 100px;
    width: 100px;
    position: relative;
    -webkit-transform-origin: 50px 150px;
    -moz-transform-origin: 50px 150px;
    -ms-transform-origin: 50px 150px;
    -o-transform-origin: 50px 150px;
    transform-origin: 50px 150px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

#colorWheel span {
    position: absolute;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    border-style: solid;
    border-width: 150px 24px;
    box-sizing: border-box;
}

#colorWheel span.color01 {
    -webkit-transform: rotate(-81deg);
    -moz-transform: rotate(-81deg);
    -ms-transform: rotate(-81deg);
    -o-transform: rotate(-81deg);
    transform: rotate(-81deg);
}

#colorWheel span.color02 {
    -webkit-transform: rotate(-63deg);
    -moz-transform: rotate(-63deg);
    -ms-transform: rotate(-63deg);
    -o-transform: rotate(-63deg);
    transform: rotate(-63deg);
}

#colorWheel span.color03 {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#colorWheel span.color04 {
    -webkit-transform: rotate(-27deg);
    -moz-transform: rotate(-27deg);
    -ms-transform: rotate(-27deg);
    -o-transform: rotate(-27deg);
    transform: rotate(-27deg);
}

#colorWheel span.color05 {
    -webkit-transform: rotate(-9deg);
    -moz-transform: rotate(-9deg);
    -ms-transform: rotate(-9deg);
    -o-transform: rotate(-9deg);
    transform: rotate(-9deg);
}

#colorWheel span.color06 {
    -webkit-transform: rotate(9deg);
    -moz-transform: rotate(9deg);
    -ms-transform: rotate(9deg);
    -o-transform: rotate(9deg);
    transform: rotate(9deg);
}

#colorWheel span.color07 {
    -webkit-transform: rotate(27deg);
    -moz-transform: rotate(27deg);
    -ms-transform: rotate(27deg);
    -o-transform: rotate(27deg);
    transform: rotate(27deg);
}

#colorWheel span.color08 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#colorWheel span.color09 {
    -webkit-transform: rotate(63deg);
    -moz-transform: rotate(63deg);
    -ms-transform: rotate(63deg);
    -o-transform: rotate(63deg);
    transform: rotate(63deg);
}

#colorWheel span.color10 {
    -webkit-transform: rotate(81deg);
    -moz-transform: rotate(81deg);
    -ms-transform: rotate(81deg);
    -o-transform: rotate(81deg);
    transform: rotate(81deg);
}

.pointer {
    border-left: 3px solid black;
    background-color: black;
    position: absolute;
    height: 150px;
    top: -1%;
    left: 22%;
    z-index: 999;
    transform-origin: bottom;
}

.pointerEnd {
    border: 3px solid black;
    background-color: black;
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    top: -6%;
    margin-left: -7px;
    z-index: 999;
    transform-origin: bottom;
}