.pie {
    background-color: #fff;
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
}

.pie-small {
    background-color: #fff;
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
}

.pie-small span {
    outline: none;
    background-color: lightgreen;
    border: thin solid white;
    position: absolute;
    // cursor: pointer;
    width: 100%;
    height: 100%;
    left: 50%;
    bottom: 50%;
    transform-origin: bottom left;
    transition: all 200ms ease-out;
}

.pie span {
    outline: none;
    background-color: lightgreen;
    border: thin solid white;
    position: absolute;
    // cursor: pointer;
    width: 100%;
    height: 100%;
    left: 50%;
    bottom: 50%;
    transform-origin: bottom left;
    transition: all 200ms ease-out;
}

.pointer_1 {
    border-left: 3px solid black;
    position: absolute;
    height: 145px;
    top: -8%;
    left: 50%;
    z-index: 999;
    transform-origin: bottom;
}

.pointer_1_small {
    border-left: 3px solid black;
    position: absolute;
    height: 90px;
    top: -8%;
    left: 50%;
    z-index: 999;
    transform-origin: bottom;
}

.pointerEnd_1 {
    border: 3px solid black;
    background-color: black;
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    top: -6%;
    margin-left: -7px;
    z-index: 999;
    transform-origin: bottom;
}

// .pie button:hover,
// .pie button:focus:hover {
//     box-shadow: 0px 0px 10px 5px #515151;
//     z-index: 1;
// }
// .pie button .text,
// .pie button:focus .text {
//     position: absolute;
//     bottom: 30px;
//     padding: 0px;
//     color: #333;
//     left: 30px;
// }
